<template>
  <v-container class="cover-editor">
    <v-breadcrumbs
      :items="[
        { text: this.$t('home'), to: '/' },
        { text: this.$t('covereditor'), to: { name: 'CoverEditor' } }
      ]"
      large
      class="px-3"
    ></v-breadcrumbs>
    <v-row>
      <v-col cols="8">
        <v-card>
          <v-data-table
            v-model="selectedImage"
            :loading="!localImageList"
            :headers="headers"
            :items="localImageList || []"
            single-select
            item-key="coverCode"
            @click:row="(item, { isSelected, select }) => select(true)"
          >
            <template #top>
              <v-toolbar flat rounded>
                <v-btn
                  text
                  color="primary"
                  @click="
                    newImageUploadShow = true;
                    uploadMetadata = {};
                    uploadFile = null;
                  "
                >
                  {{ $t("new-image") }}
                </v-btn>
                <v-btn
                  text
                  color="error"
                  :disabled="!selectedImage.length"
                  @click="showDeleteImageConfirm = true"
                >
                  {{ $t("delete-image") }}
                </v-btn>
                <v-spacer />
              </v-toolbar>
            </template>
            <template v-slot:item.coverImage="{ item }">
              <img :src="item.coverImage" style="width: 50px; height: 50px" />
            </template>
            <template v-slot:item.caption="{ item }">
              {{ viewCaption(item.caption, $i18n.locale) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-toolbar class="green lighten-5" flat dense>
            <span class="title">
              {{ $t("details") }}
            </span>
          </v-toolbar>
          <v-card-text v-if="!selectedImage.length">
            {{
              $t(
                "to-view-details-chose-an-order-from-the-list-or-create-a-new-one"
              )
            }}
          </v-card-text>
          <v-card-text v-else>
            <div
              style="height: 100%;width: 100%;object-fit: cover;"
              @click="showLargeImage = true"
            >
              <img
                :src="selectedImage[0].coverImage"
                style="height:100%;width:100%"
              />
            </div>
            <v-text-field
              :label="lang"
              v-model="selectedImage[0].caption[lang]"
              v-for="lang in languageCodes"
              :key="lang"
              v-on:change="onChangeCaption($event, selectedImage[0])"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="showLargeImage">
      <template v-if="selectedImage.length == 1">
        <div
          style="height:100%;width:100%;object-fit: cover;"
          @click="showLargeImage = false"
        >
          <img
            :src="selectedImage[0].coverImage"
            style="height:100%;width:100%"
          />
        </div>
      </template>
    </v-overlay>
    <v-overlay :value="showDeleteImageConfirm">
      <v-card v-if="selectedImage.length == 1" max-width="600">
        <h1>
          {{ $t("cover-code") + " : " + selectedImage[0].coverCode }}
        </h1>
        <v-img
          :src="selectedImage[0].coverImage"
          @click="showDeleteImageConfirm = false"
        />
        <v-row align="center" justify="space-around">
          <v-btn
            text
            :disabled="!selectedImage.length"
            elevation="2"
            x-large
            @click="showDeleteImageConfirm = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            text
            color="error"
            :disabled="!selectedImage.length"
            elevation="2"
            x-large
            @click="deleteImage"
          >
            {{ $t("delete-image") }}
          </v-btn>
        </v-row>
      </v-card>
    </v-overlay>
    <v-overlay :value="newImageUploadShow">
      <v-card elevation="2" min-width="500" align="center" class="pa-2">
        <h1>
          {{ $t("new-image") }}
        </h1>
        <br />
        <v-file-input
          :label="$t('file-input')"
          prepend-icon="mdi-camera"
          accept="image/jpeg,image/png"
          outlined
          full-width
          v-model="uploadFile"
        />
        <br />
        <v-text-field v-model="uploadCoverCode" :label="$t('cover-code')" />
        <br />
        <h2>
          {{ $t("cover-caption") }}
        </h2>
        <v-card-text>
          <v-text-field
            :label="lang"
            v-model="uploadMetadata[lang]"
            v-for="lang in languageCodes"
            :key="lang"
          />
        </v-card-text>
        <br />
        <v-row align="center" justify="space-around">
          <v-btn text elevation="2" x-large @click="newImageUploadShow = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn text elevation="2" x-large @click="uploadNewImage()">
            {{ $t("upload-image") }}
          </v-btn>
        </v-row>
        <br />
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { cloudApi } from "../api";
import path from "path";

export default {
  name: "CoverEditor",
  data: () => ({
    selectedImage: [],
    showLargeImage: false,
    showDeleteImageConfirm: false,
    newImageUploadShow: false,
    uploadMetadata: {},
    uploadFile: null,
    uploadCoverCode: "",
    languageCodes: []
  }),
  mounted() {
    cloudApi
      .get("get-option", { params: { OptionType: "Language" } })
      .then(this.setLanguageCodes);
  },
  computed: {
    ...mapState(["coverImages"]),
    headers() {
      return [
        { text: this.$t("cover-image"), value: "coverImage" },
        { text: this.$t("cover-code"), value: "coverCode" },
        { text: this.$t("cover-caption"), value: "caption" }
      ];
    },
    localImageList() {
      if (!this.coverImages) {
        this.fetchCoverImageList();
        return null;
      }
      var result = [];
      for (var coverCode in this.coverImages.Images) {
        result.push({
          coverImage: this.coverImages.Images[coverCode],
          coverCode: coverCode,
          caption: this.coverImages.Captions[coverCode]
        });
      }
      return result;
    }
  },
  methods: {
    ...mapActions(["fetchCoverImageList"]),
    ...mapActions(["deleteCoverImage"]),
    setLanguageCodes(response) {
      this.languageCodes = response.data.Items;
    },
    sendUpdateImage(targetFileName, contents, caption) {
      console.log(targetFileName, caption);
      console.log(contents.substr(0, 10));
    },
    uploadNewImage() {
      var targetFileName =
        this.uploadCoverCode + path.extname(this.uploadFile.name);
      var reader = new FileReader();
      reader.onload = e => {
        cloudApi
          .post("upload-image", {
            FileName: targetFileName,
            Caption: this.uploadMetadata,
            ContentBase64: e.target.result
          })
          .then(() => {
            this.newImageUploadShow = false;
            this.fetchCoverImageList();
          });
      };
      reader.readAsDataURL(this.uploadFile);
    },
    deleteImage() {
      this.showDeleteImageConfirm = false;
      if (this.selectedImage.length) {
        this.deleteCoverImage(this.selectedImage[0].coverCode);
        this.selectedImage = [];
      }
    },
    onChangeCaption(newText, selectedImage) {
      cloudApi.post("update-image-caption", {
        FileName: selectedImage.coverCode,
        Caption: selectedImage.caption
      });
    },
    viewCaption(x, loc) {
      if (x[loc]) {
        return x[loc];
      } else {
        if (x["en"]) {
          return x["en"];
        } else {
          return "-";
        }
      }
    }
  },
  watch: {}
};
</script>
